import CLRequest from '../request'


// 资料下载目录
export function getDownloadCatalogs() {
  return CLRequest.get({ 
    url: "/api/download/treeList",
  })
}
// 资料类别列表
export function getDownloadTypeList(params) {
    return CLRequest.get({ 
      url: `/api/download/getDetails`,
      params
       
    })
}
// 资料下载搜索
export function getDownloadSearchList(params) {
    return CLRequest.get({ 
      url: `/api/download/selectDownloadByTitle` ,
      params
    })
}

// 资料下载搜索
export function getDownloadByParentId(params) {
  return CLRequest.get({ 
    url: `/api/download/selectDownloadByParentId` ,
    params
  })
}

// 资料详情
export function getDownloadDetail(id) {
    return CLRequest.get({ 
      url: `/api/download/getDetails/`+id ,
    })
}
// 常见问题
export function getCommonQuestion(params) {
    return CLRequest.get({ 
      url: `/api/faq/faqTypeList` ,
      params
    })
}


// 常见问题搜索
export function getCommonQuestionSearchList(params) {
  return CLRequest.get({ 
    url: `/api/faq/selectFaqByTitle` ,
    params
  })
}

// 资料下载埋点
export function userDownloadApi(data) {
  return CLRequest.post({ 
    url: "/api/download/userDownload", 
    data
  })
}

